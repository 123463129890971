<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="clearfix">
    <form @submit.prevent="$emit('submit')">
      <input type="submit" class="hidden" />
      <vs-input
        v-validate="'required|email'"
        data-vv-validate-on="blur"
        name="email"
        type="email"
        label-placeholder="Email"
        placeholder="Email"
        v-model="body.email"
        class="w-full mt-6"
      />
      <span class="text-danger text-xs pb-2">{{ errors.first('email') }}</span>

      <vs-input
        ref="password"
        type="password"
        data-vv-validate-on="blur"
        v-validate="'required|min:8'"
        name="password"
        label-placeholder="Password"
        placeholder="Password"
        v-model="body.password"
        autocomplete
        class="w-full mt-6"
      />
      <span class="text-danger text-xs pb-2">{{ errors.first('password') }}</span>
    </form>
    <br />
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        email: '',
        password: ''
      }
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== '' && this.password !== '';
    }
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });

        return false;
      }
      return true;
    },
    registerUserJWt() {
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return;

      const payload = {
        userDetails: {
          displayName: this.displayName,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirm_password
        },
        notify: this.$vs.notify
      };
      this.$store.dispatch('auth/registerUserJWT', payload);
    }
  }
};
</script>
