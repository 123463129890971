<template>
  <section class="w-full pb-5 flex flex-col">
    <div class="vx-card__title mb-12">
      <h4 class="mb-4">Welcome back!</h4>
      <p v-if="selectTenantVisibility">No account yet? <router-link to="/signup">Register</router-link></p>
    </div>
    <!-- visibility: {{ selectTenantVisibility }}<br />
    isLoggedin: {{ isLoggedin }} -->

    <div v-if="!selectTenantVisibility" class="text-left mt-base">
      <p class="mb-5 text-sm">Please select the account/organisation you would like to login to:</p>
      <vx-field
        type="select"
        autocomplete
        class="w-full"
        placeholder="Select account/organisation"
        v-validate="'required'"
        v-model="tenantId"
        as="account"
        :options="{
          items: tenants
        }"
      />
      <vs-button class="mb-base mt-6" @click="tenant">Continue</vs-button>
    </div>
    <div v-else class="flex flex-col flex-1">
      <!-- <div class="flex justify-center">
        <vs-button type="flat" color="primary"><span class="text-sm">Sign in with <strong>LinkedIn</strong></span></vs-button>
        <vs-button type="flat" color="danger"><span class="text-sm">Sign in with <strong>Google</strong></span></vs-button>
      </div>
      <vs-divider class="mb-base mt-5">or</vs-divider> -->
      <vs-alert color="danger" v-if="message" class="transform -translate-y-5 mb-2">
        <p class="text-xs">{{ message }}</p>
      </vs-alert>
      <vs-alert color="danger" v-if="storageTid" class="transform -translate-y-5">
        <p class="text-xs">Access denied: You do not have rights to access the tenant {{ storageTid }}</p>
      </vs-alert>
      <Form class="text-left flex-1 flex flex-col" ref="form" @submit="login">
        <div class="flex flex-wrap justify-between my-1">
          <!--<vs-checkbox v-model="checkbox_remember_me" class="mb-1">Remember Me</vs-checkbox>-->
          <router-link class="ml-auto" to="/forgotpassword">Forgot Password?</router-link>
        </div>

        <vs-button class="mb-base mt-6" @click="login">Sign in</vs-button>
        <p class="text-xs mt-auto">&copy; {{ $moment().year() }} - Flipmind. All Rights Reserved.</p>
      </Form>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Form from '@/views/pages/auth/Form';
import auth0 from '@/auth0';
export default {
  components: {
    Form
  },
  data: () => ({
    message: '',
    tenantId: null,
    storageTid: localStorage.getItem('tid')
  }),
  computed: {
    ...mapState('auth', {
      tenants: state => {
        return (state.tenants || []).map(item => ({
          value: item.id,
          text: item.companyName
            ? item.companyName
            : item.firstName && item.lastName
            ? `${item.firstName} ${item.lastName}`
            : item.email || item.firstName
        }));
      }
    }),
    ...mapGetters('auth', ['isLoggedin', 'tenantSelected']),
    selectTenantVisibility() {
      if (!this.isLoggedin) return true;
      return this.tenantSelected;
    }
  },
  methods: {
    async login() {
      const $ctrl = this;
      const form = this.$refs.form;
      return await form.$validator.validateAll().then(valid => {
        if (valid) {
          auth0.login(form.body).catch(e => {
            $ctrl.message = e.error_description;
          });
        }
      });
    },
    async tenant() {
      return await this.$validator.validateAll().then(valid => {
        if (valid) {
          this.$store.dispatch('auth/tenant', { id: this.tenantId }).then(tenant => {
            if (tenant) this.$router.push(this.$route.query.redirect || '/');
          });
        }
      });
    }
  },
  async beforeCreate() {
    await this.$auth0
      .handleAuthentication()
      .then(() => {
        if (this.selectTenantVisibility) this.$router.push(this.$route.query.redirect || '/').catch(() => {});
      })
      .catch(e => {
        if (e.error === 'unauthorized') return this.$router.push('/unauthorised').catch(() => {});
      });
  }
};
</script>
